import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { Resource, Paragraph } from 'components/Landing/Resource/Resource'

const DomainIncident: React.FC<RouteComponentProps> = () => {
  return (
    <Resource title='Domain Incident'>
      <Paragraph>
        About a month ago, our domain name (doseapp.io) expired, causing an
        outage of the website and making the app unavailable for some people.
        Our e-mail address was inaccessible as well, so most of you
        couldn&apos;t contact me either.
      </Paragraph>
      <Paragraph>
        I tried renewing the domain, but my Namecheap account had been blocked
        due to &quot;suspicious activity&quot;. I filled a support ticket, but
        it took them some time to verify me and grant access to the account. I
        renewed the domain as soon as possible.
      </Paragraph>
      <Paragraph>
        The app is now fully available. No data has been leaked. I am extremely
        sorry for this incident and I assure you it will not happen in the
        future.
      </Paragraph>
      <Paragraph>
        Thanks for sticking around!
        <br />
        &mdash; Michał, Developer of Dose
      </Paragraph>
    </Resource>
  )
}

export default DomainIncident
